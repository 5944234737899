<template>
    <div class="slide-container">
        
        <div class="content">
            <div class="upload-wrapper" v-show="!isUploaded">
                <Upload
                    :cosName="cosName"
                    :prefix="prefix"
                    :filename="filename"
                    @uploadSuccess="uploadSuccess"
                ></Upload>
            </div>
            <div class="slide-area-wrapper" @contextmenu.prevent >
                <div class="slide-area"
                    @contextmenu.prevent
                    @click.right.stop="prevPage"
                >
                    <div class="slide-show-area" id="slide-show-area"></div>
                    <!-- <div class="slide-show-area-mask"
                        @click.stop.prevent="test"
                    >
                        <div class="left" @click.stop="prevPage"></div>

                        <div class="right" @click.stop="nextPage"></div>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- <Loading></Loading> -->
    </div>
</template>

<script>
/* eslint-disable no-undef */

import Upload from '@/views/components/widget/Upload';
import Loading from "@/views/components/mask/Loading";
import { mapGetters } from 'vuex'

import { getCourseInfo, getSectionInfo } from '@/utils/common'
import { genResId } from '@/api/web'
import { getAdminSectionData, saveAdminSectionRes } from "@/api/admin";

export default {
    name: "slide-ppt",
    data: function () {
        return {
            curEnv: '',

            resId: '',
            filename: '',

            cosName: 'doc',
            prefix: 'slide',

            isUploaded: false,
            docPreview: null,
        };
    },
    computed: {
        ...mapGetters([
            'pid',
            'cid',
            'sid',
        ]),
    },
    created() {
    },
    activated() {
    },
    mounted() {
        // 加载userInfo
        this.loadUserInfo();
    },
    deactivated() {
    },
    destroyed() {
        this.resId = ''
        this.filename = ''
    },
    methods: {
        getCourseInfo,
        getSectionInfo,
        async loadUserInfo() {
            await this.$store.dispatch('user/getUserInfo');
        },
        loadPage(cid, sid, pid, curEnv = null) {
            if (curEnv) {
                this.curEnv = curEnv
            }

            if (this.curEnv == 'classroom') {
                this.loadClassroomData(cid, sid)

            } else if (this.curEnv == 'editor') {
                this.loadEditorData(cid, sid)
            }
        },
        async loadClassroomData(cid, sid) {
            this.$store.commit('web/SetCourseId', cid)
            this.$store.commit('web/SetSectionId', sid)

            // 获取小节resId
            let res = await getAdminSectionData({ cid, sid })
            if (res && res.data) {
                this.resId = res.data.resId
                this.filename = this.resId
            
                this.$store.commit('web/SetProjectId', this.resId)
            }

            // 加载上课信息
            this.getCourseInfo(cid)

            // 加载小节信息
            this.getSectionInfo(sid)

            // 加载小节数据
            this.loadData()
        },
        async loadEditorData(cid, sid) {
            this.$store.commit('web/SetCourseId', cid)
            this.$store.commit('web/SetSectionId', sid)

            // 获取小节resId
            let res = await getAdminSectionData({ cid, sid })
            if (res && res.data) {
                this.resId = res.data.resId
                this.filename = this.resId

                this.$store.commit('web/SetProjectId', this.resId)
            }

            // 加载小节信息
            this.getSectionInfo(sid)

            // 加载小节数据
            this.loadData()
        },
        async loadData() {
            if (this.filename == '') {
                let res = await genResId()
                if (res && res.data) {
                    this.resId = res.data.resId
                    this.filename = this.resId
                }

            } else {
                let objectUrl = 'https://doc-1253970464.cos.ap-beijing.myqcloud.com/slide/' + this.filename + '.pptx';
                var url = await COSDocPreviewSDK.getPreviewUrl( {
                    objectUrl: objectUrl,
                    copyable: 0,  // 是否可以复制文件内容
                    htmlwaterword: ''  // 水印内容
                })
                if (url != "") {
                    this.isUploaded = true

                    this.docPreview = COSDocPreviewSDK.config({
                        url: url,
                        mount: document.querySelector('.slide-show-area'),    // 挂载节点，默认会挂载到 body 下，可根据需要自定义加载节点
                        mode: 'normal',
                        commandBars: [
                            {
                                cmbId: "PlayComponentToolbar",
                                attributes: {
                                    visible: false, // 隐藏组件
                                    enable: false, // 禁用组件
                                }
                            }
                        ],
                        commonOptions: {
                            isShowTopArea: false, //隐藏顶部区域（头部和工具栏）。
                            isShowHeader: false,  //隐藏头部区域。
                            isBrowserViewFullscreen: false,
                            isIframeViewFullscreen: true,
                        },
                        pptOptions: {
                            isInSafeMode: true,
                            isShowBottomStatusBar: false
                        }
                    })

                    // 务必等待 demo ready 之后再调用高级 api，demo 对象是指 JS-SDK 实例化后的对象，参考使用说明->初始化
                    await this.docPreview.ready()

                    const app = this.docPreview.PPTApplication()
                    await app.ActivePresentation.SlideShowWindow.View.GotoSlide(1)
                    await app.ActivePresentation.SlideShowSettings.Run()
                }
                // this.$store.commit("web/SetIsShowLoading", false);
            }
        },
        async prevPage() {
            // console.log('prev page')
            await this.docPreview.PPTApplication().ActivePresentation.SlideShowWindow.View.GotoPreClick()
        },
        async nextPage() {
            // console.log('next page')
            await this.docPreview.PPTApplication().ActivePresentation.SlideShowWindow.View.GotoNextClick()
        },
        async uploadSuccess() {
            // this.$store.commit("web/SetIsShowLoading", true);
            // 保存到数据库
            await saveAdminSectionRes({ cid: this.cid, sid: this.sid, resId: this.resId })
            // 上传完成后，立即加载
            this.loadData()
        },
        handleCmd(data) {
            switch (data.cmd) {
                case 'save':
                    this.save()
                    break
            }
        },
        save() {
            //
        },
    },
    components: {
        Upload,
        // Loading
    },
};
</script>

<style lang="scss" scoped>
.slide-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #222;
        position: relative;

        .upload-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
        }
        
        .slide-area-wrapper {
            // flex: 1;
            width: 80%;

            // border-left: 1px solid #444;
            background-color: #222;
            display: flex;
            justify-content: center;
            align-items: center;

            .slide-area {
                /*宽高比70%*/
                height: 0;
                width: 100%;
                // padding-bottom: 56.25%; // 16:9
                padding-bottom: 62.5%; // 16:10
                max-height: 100%;

                display: flex;
                justify-content: center;
                align-items: center;
                // background: black;
                position: relative;

                .slide-show-area {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .slide-show-area-mask {
                    position: absolute;
                    
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .left {
                        // flex: 1;
                        width: 30%;
                        height: 100%;
                    }
                    .right {
                        // flex: 1;
                        width: 30%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>
